@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-select;

.@{base-cls} {
  display: flex;
  gap: 8px;
  position: relative;

  &:not(.@{base-cls}-borderless) {
    border-bottom: 1px solid @color-secondary-95;
  }

  &.@{base-cls}-hide {
    .@{base-cls}-dropdown{
      display: none;
    }
  }

  .@{base-cls}-selector {
    padding: 0 32px 0 0;
    flex: 1;
    min-height: 24px;
    display: flex;
    align-items: center;

    .@{base-cls}-selection-search {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
      }
    }

    .@{base-cls}-selection-item {
      .mixin-text(small)
    }
  }

  .@{base-cls}-arrow {
    color: @color-text-100;
    font-size: 24px;
    line-height: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.@{base-cls}-dropdown {
  position: absolute;
  background: @color-background-50;
  border: 1px solid @color-secondary-70;
  box-shadow: @box-shadow-50;
  border-radius: 4px;
  z-index: @layer-300;

  &.@{base-cls}-dropdown-hidden {
    display: none !important;
  }

  .rc-virtual-list-holder {
    padding: 16px;
  }

  .@{base-cls}-item-option {
    cursor: pointer;
    white-space: nowrap !important;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.@{base-cls}-item-option-selected {
      .@{base-cls}-item-option-content {
        color: @color-primary-50;
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;