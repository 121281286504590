@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-directory-list;

.@{base-cls} {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    position: relative;

    .@{base-cls}-body {
        width: 100%;
        position: absolute;
        left: 0;

        &.expand {
            display: block;
        }

    }

    .@{base-cls}-content {
        visibility: hidden;
        opacity: 0;
    }

    .@{base-cls}-card {
        width: 180px !important;
    }

    .@{base-cls}-link {
        background-color: @color-primary-50;
        color: @color-background-50;
    }

    .cmp-card {
        width: 180px !important;
        height: 102px !important;
        cursor: pointer;

        &-body {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 64px;
            line-height: 75px;
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;