@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-page-list-card;

.@{base-cls} {
  padding: 24px;
  overflow: hidden;
  border: 1px solid @color-secondary-70;
  box-shadow: @box-shadow-50;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  min-width: 450px;
  min-height: 130px;
  flex: 1;
  background-color: @color-secondary-50;

  &.@{base-cls}-mobile{
    min-width: fit-content;
    flex-direction: column;
    gap: 8px;
  }

  &.@{base-cls}-total {
    border-radius: 0;
    background-color: @color-secondary-50;
    margin-left: -16px;
    margin-right: -16px;

    .@{base-cls}-wrap {
      padding: 0 16px;
    }
  }

  .@{base-cls}-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 8px;
    width: 100%;

    .@{base-cls}-data {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      grid-row-gap: 8px;

      .@{base-cls}-field {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1;
        grid-row-gap: 4px;

        &:nth-child(2n) > * {
          text-align: right;
          justify-content: flex-end;
        }

        .@{base-cls}-value {
          color: @color-text-100;
          .mixin-text(medium);
          align-content: center;
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
        }
      }

    }

  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;