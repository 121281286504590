@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-search;

.@{base-cls} {
  flex: 1;

  inset: 0;
  padding: 14px;
  background: white;
  z-index: 1;


  &.@{base-cls}-tablet:not(.@{base-cls}-show-search-tablet) {
    .@{base-cls}-input {
      display: none;
    }
  }

  &.@{base-cls}-tablet.@{base-cls}-show-search-tablet {
    position: absolute;
  }

  .cmp-popover-inner {
    padding: 0 !important;
  }

  .@{base-cls}-button {
    position: relative;

    .@{base-cls}-check {
      display: inline-block;
      position: absolute;
      top: -3px;
      right: -3px;
      width: 8px;
      height: 8px;
      background-color: @color-error-100;
      border-radius: 50%;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;