@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-field-list;

.@{base-cls}-input {
    cursor: pointer;

    .@{base-cls}-input-icon {
        display: inline-block;
        line-height: 0;
        background-color: @color-secondary-80;
        padding: 4px;
        border-radius: 50%;

        .icon {
            color: @color-primary-50;
            font-size: 18px;
        }
    }
}

.@{base-cls}-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: nowrap;

    .cmp-input-search {
        box-shadow: none !important;
        margin-bottom: 16px;
    }

    .cmp-checkbox-group {
        max-height: 350px;
        overflow: auto;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;