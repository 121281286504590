@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-404;

.@{base-cls}-title {
    i {
        svg {
            width: 252px;
            height: 294px;
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;