@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-button-projects;

.@{base-cls} {
  position: relative;
  top: 1px;

  &:not(.@{base-cls}-mobile) {
    .cmp-button-text {
      padding: 4px 16px 4px 4px;
    }
  }

  .cmp-button {
    background-color: @color-secondary-50;
    border-radius: 40px;
    border: 1px solid @color-secondary-100;
  }

  .ant-typography {
    margin: 0;
  }

  button {
    padding: 0 !important;
  }

  .ant-btn:hover {
    .icon {
      display: inline-block;
    }
  }

  .icon {
    display: none;
    position: relative;
    top: 3px;
  }

  &.@{base-cls}-mobile{
    margin: 0;
    .cmp-button {
      border: 0px;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;