@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-search-result;

.@{base-cls}-drawer {
  z-index: @layer-210 - 2 !important;

  .cmp-drawer-close {
    z-index: 3;
  }

  &.cmp-drawer.cmp-drawer-open {
    z-index: @layer-210 - 2 !important;
    height: auto !important;
  }

  &.@{base-cls}-drawer-mobile {
    .cmp-drawer-content-wrapper {
      min-width: calc(100%) !important;
      max-width: calc(100%) !important;
      left: 0;
    }
  }

  &.@{base-cls}-drawer-tablet {
    .cmp-drawer-content-wrapper {
      min-width: calc(100%) !important;
      max-width: calc(100%) !important;
      left: 0;
    }

    .@{base-cls}-view {
      flex: none !important;

      .ant-tabs-content-holder {
        display: none !important;
      }
    }

    .@{base-cls}-list {
      min-width: 240px !important;
    }
  }

  .@{base-cls}-pagination {
    padding: 12px;
    z-index: 2;
    position: sticky;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .cmp-pagination {
      margin: 0 !important;
    }
  }

  .@{base-cls}-toolbar {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: white;

    .icon {
      font-size: 20px;
      cursor: pointer;
    }

    .cmp-select {
      min-width: 160px;
    }
  }

  .cmp-knowledge-list-item {
    padding: 8px 24px !important;
  }

  .cmp-drawer-mask {
    top: @header-height;
    background-color: transparent;
  }

  .cmp-drawer-content-wrapper {
    min-width: calc(100% - @header-height) !important;
    max-width: calc(100% - @header-height) !important;
    top: @header-height;
    left: @header-height;
    bottom: 0 !important;
    height: auto !important;
  }

  .cmp-drawer-wrapper-body {
    overflow: hidden !important;

    .cmp-drawer-body {
      padding: 0 !important;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      overflow: hidden;
      height: calc(100vh - @header-height);
      width: 100%;

      .@{base-cls}-view {
        flex: 2;
        overflow: hidden;

        .ant-tabs {
          height: 100%;
          padding: 0;
          width: 100%;
          position: relative;
          top: -1px;
          left: -2px;

          .ant-tabs-tab {
            border-radius: 0;
            display: flex;
            justify-content: space-between;
          }

          .ant-tabs-content-holder {
            overflow: auto;
            padding: 24px 60px 28px 0;
          }
        }

      }

      .@{base-cls}-list {
        flex: 1;
        border-right: 2px solid @color-secondary-80;
        min-width: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;

        .cmp-knowledge-list {
          flex: 1;
        }
        
      }
    }
  }


}

.@{base-cls}-empty-icon {
  font-size: 10em;
  color: @color-secondary-70;
  svg {
    width: auto !important;
    height: auto !important;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;