@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-table-filter;

.@{base-cls}-dropdown {
    position: relative;
    left: 50%;
    top: 4px;
    background: @color-background-50;
    border: 1px solid @color-secondary-70;
    box-shadow: @box-shadow-50;
    border-radius: 4px;
    z-index: @layer-300;

    &> * {
        padding: 16px;
    }

    .cmp-checkbox-group {
        max-height: 200px;
        overflow: auto;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;