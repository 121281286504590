@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";


@base-cls: page-main-menu-mobile;

.@{base-cls}-btn {

    &.@{base-cls}-btn-mobile {
        margin-left: 16px;
    }
}

.@{base-cls}-btn-add {
    width: 100%;
}

.@{base-cls} {
    padding: 0;
}

.@{base-cls}-items {
    margin-left: 32px;
}

.@{base-cls}-icon-left {
    .cmp-button-before-icon {
        transform: rotate(270deg);
    }
}

.@{base-cls}-icon-rigth {
    .cmp-button-after-icon {
        transform: rotate(90deg);
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;