@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-page-list;

.@{base-cls} {

  .@{base-cls}-wrapper-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    grid-gap: 16px;
    gap: 16px;
    flex-wrap: wrap;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;