@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-alert;


.@{base-cls} {
  display: flex;
  gap: 6px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  background-color: @color-background-50;
  min-width: 320px;
  position: relative;

  &.@{base-cls}-motion-leave {
    display: none;
  }

  .@{base-cls}-content {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }

  .@{base-cls}-icon {
    display: none;
  }

  .@{base-cls}-message {
    .mixin-text(large);
    max-width: 320px;
    word-wrap: break-word;
  }

  .@{base-cls}-description {
    .mixin-text(small);
    max-width: 320px;
    word-wrap: break-word;
  }

  .@{base-cls}-close-icon {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 18px;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: @color-text-100;
    line-height: 100%;
  }
}

.@{base-cls} {

  &.@{base-cls}-error {
    background-color: @color-error-10;
  }

  &.@{base-cls}-success {
    background-color: @color-success-10;
  }

  &.@{base-cls}-info {
    background-color: @color-primary-10;
  }

  &.@{base-cls}-warning {
    background-color: @color-warning-10;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;