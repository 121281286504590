@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-button-news;

.@{base-cls} {
    position: relative;

    .icon {
        color: @color-text-100 !important;
    }

    .cmp-button-after-icon {

        .@{base-cls}-check {
            display: inline-block;
            position: absolute;
            top: -3px;
            right: -3px;
            width: 8px;
            height: 8px;
            background-color: @color-error-100;
            border-radius: 50%;
        }

        .@{base-cls}-check:before {
            content: " ";
            position: absolute;
            border: 3px solid @color-error-100;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1.2s ease-in-out infinite;
        }

        .@{base-cls}-check div:nth-child(2) {
            animation-delay: -0.4s;
        }

        @keyframes lds-ripple {
            0% {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                opacity: 0;
            }

            4.9% {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                opacity: 0;
            }

            5% {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                opacity: 1;
            }

            100% {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 26px;
                height: 26px;
                opacity: 0;
            }
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;