@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-date-picker;

.ant-picker {
  min-height: 30px !important;
  padding: 0 !important;

  .ant-picker-input {
    border-bottom: 1px solid @color-secondary-95;


    input[value] {
      .mixin-text(small);
      font-weight: @font-weight-bodysmall;
      padding-bottom: 4px;
    }
  }

  .ant-picker-suffix {
    position: relative;
    top: -2px;
    color: @color-text-100;
  }

  .ant-picker-clear {
    position: absolute;
    top: 9px;
    color: @color-text-100;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;