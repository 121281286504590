@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-progress;


.@{base-cls}-body {
    display: flex;
    align-items: center;
    gap: 8px;
}

.@{base-cls} {
    width: 100%;

    .@{base-cls}-inner {
        width: 100%;
        background-color: @color-secondary-80;
        border-radius: 100px;

        .@{base-cls}-bg {
            background-color: @color-primary-30;
            border-radius: 100px;
            transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
        }
    }
}

.@{base-cls}-sts-success {
    .@{base-cls}-bg {
        background-color: @color-success-100 !important;
    }

    .@{base-cls}-steps-item {
        &.@{base-cls}-steps-item-active {
            background-color: @color-success-100 !important;
        }
    }
}

.@{base-cls}-sts-exception {
    .@{base-cls}-bg {
        background-color: @color-error-100 !important;
    }

    .@{base-cls}-steps-item {
        &.@{base-cls}-steps-item-active {
            background-color: @color-error-100 !important;
        }
    }
}

.@{base-cls}-sts-warning {
    .@{base-cls}-bg {
        background-color: @color-warning-100 !important;
    }

    .@{base-cls}-steps-item {
        &.@{base-cls}-steps-item-active {
            background-color: @color-warning-100 !important;
        }
    }
}

.@{base-cls}-steps-outer {
    display: flex;
    gap: 8px;

    .@{base-cls}-steps-item {
        width: 100% !important;
        border-radius: 100px;
        background-color: @color-secondary-80;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;