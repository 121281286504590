@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-page-list-title;

.@{base-cls} {
  width: 100%;

  .@{base-cls}-value {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;

    &.@{base-cls}-value-edit {
      .@{base-cls}-el {
        cursor: pointer;

        &:hover {
          color: @color-primary-50;
        }
      }
    }
  }

  .icon {
    cursor: pointer;
    font-size: 24px;
    color: @color-secondary-100 !important;
  }

  .@{base-cls}-el {
    .mixin-typography-text(title);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    color: @color-text-100;
    word-break: break-word;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;