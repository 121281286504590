@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main;

.@{base-cls} {
  min-height: 100vh;
  min-width: 100%;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;

  &:not(.@{base-cls}-tablet) {
    .@{base-cls}-button-projects {
      min-width: 10vw;
    }
  }

 

  .@{base-cls}-suspense-loader{
      min-height: calc(100vh - @header-height);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
  }

  .@{base-cls}-sider {
    position: sticky;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    border-right: 2px solid @color-secondary-80;
    background-color: @color-background-50 !important;
    width: 80px;
  }

  .@{base-cls}-logo {
    width: 80px;
    height: @header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid @color-secondary-80;

    img {
      width: 68px;
    }
  }

  .@{base-cls}-page {
    height: fit-content;
    flex: 1;

    .@{base-cls}-header {
      position: sticky;
      top: 0;
      z-index: @layer-200;
      height: @header-height;
      background-color: @color-background-50 !important;
      border-bottom: 2px solid @color-secondary-80;
      padding: 0 58px 0 24px;
      display: flex;
      align-items: center;
      gap: 16px;

      &.@{base-cls}-header-mobile {
        padding: 0 16px 0 0;
      }

      .@{base-cls}-header-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .cmp-popover {
          max-width: 360px;
        }

        >* {
          display: flex;
        }
      }
    }

    .@{base-cls}-content {
      background-color: @color-background-50 !important;
      height: fit-content;
    }
  }
}