@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-button-news-item;

.@{base-cls} {
  width: 100%;

  .@{base-cls}-users-item {
    min-width: 270px;
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;

    .@{base-cls}-splitter {
      width: 30px;
    }
    &:nth-child(2n) {
      background-color: @color-primary-10;
    }
  }

  .@{base-cls}-button-view {
    background-color: @color-secondary-50 !important;
    border-radius: 40px !important;
    border: 1px solid @color-secondary-100 !important;

    &.@{base-cls}-button-action-check {
      background-color: @color-success-50 !important;
      border: 1px solid @color-success-100 !important;
    }

    &.cmp-button {
      padding: 4px 8px 4px 4px !important;
    }
  }

  .@{base-cls}-button-action {
    .icon {
      color: @color-secondary-100 !important;
    }

    &.@{base-cls}-button-action-check {
      pointer-events: none;

      .icon {
        color: @color-success-100 !important;
      }
    }

    &.@{base-cls}-button-action-users-hide {
      pointer-events: none;
    }
  }

  .cmp-loader-container {
    min-height: 100px;
  }

  .@{base-cls}-card-top {
    display: flex;
    justify-content: space-between;
  }

  .@{base-cls}-content {
    word-break: break-word;
    .mixin-text(medium);
    font-weight: 400 !important;
    p {
      margin: 0;
    }

    img {
      max-width: 100%;
    }
  }

  .@{base-cls}-card-bottom {
    display: flex;
    justify-content: space-between;

    .@{base-cls}-projects .cmp-space-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .@{base-cls}-card-bottom-right {

      .@{base-cls}-face {
        gap: 8px;
        cursor: pointer;
        color: @color-secondary-100;

        &>* {
          display: inline;

          &:first-child {
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.@{base-cls}-mobile {
  .@{base-cls}-card-bottom {
    flex-direction: column-reverse;
    align-items: start;

    &>*{
      width: 100%;
    }

    .@{base-cls}-card-bottom-right {
      display: flex;
      justify-content: space-between;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;