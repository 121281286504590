@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-pagination;

.@{base-cls} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    list-style-type: none;
    padding: 0;

    &-item {
        min-width: 32px;
        border-radius: 4px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .cmp-button {
            min-width: 32px;
            height: 32px;
            padding: 0;

            span {
                color: @color-text-50;
            }
        }

        &.@{base-cls}-item-active {
            background-color: @color-primary-50;

            .cmp-button {
                span {
                    color: @color-background-50;
                }
            }
        }
    }

    .@{base-cls}-simple-pager {
        display: flex;
        align-items: center;
        gap: 8px;

        input {
            border: 0 solid @color-secondary-80;
            border-bottom-width: 1px;
            padding: 4px;
            height: 30px;
            outline: none;
            text-align: center;
        }
    }

    .@{base-cls}-next,
    .@{base-cls}-prev {
        cursor: pointer;
        max-width: 32px !important;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        .cmp-button {
            max-width: 32px;
            max-height: 32px;
        }
    }

    .@{base-cls}-next {
        transform: rotate(-90deg);
    }

    .@{base-cls}-prev {
        transform: rotate(90deg);
    }


    .@{base-cls}-jump-prev,
    .@{base-cls}-jump-next {
        .@{base-cls}-item-link {
            .@{base-cls}-item-container {
                position: relative;
                min-width: 32px;
                height: 32px;
                color: @color-text-20;
                font-size: 16px;

                .@{base-cls}-item-link-icon {
                    position: absolute;
                    z-index: @layer-200;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: all 0.2s;
                    display: block;

                    &:hover {
                        opacity: 1;
                        transition: all 0.2s;
                    }

                    &:hover~.@{base-cls}-item-ellipsis {
                        transition: all 0.2s;
                        opacity: 0;
                    }
                }

                .@{base-cls}-item-ellipsis {
                    position: absolute;
                    z-index: @layer-100;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 1;
                    transition: all 0.2s;
                    display: block;
                }
            }
        }
    }

    .@{base-cls}-options {
        display: none;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;