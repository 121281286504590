@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-checkbox;


.get-checkbox-disabled() {
  border-color: @color-secondary-95;
  background-color: @color-secondary-95;
}

.get-checkbox-checked-disabled() {
  border-color: @color-secondary-95;
  background-color: @color-secondary-95;
}


.get-checkbox-hover() {
  border-color: @color-primary-30;
  background-color: @color-background-50;
}

.get-checkbox-default() {
  border: 1px solid @color-secondary-95;
  border-collapse: separate;
  display: block;
  position: relative;
  transition: all .3s;
}

.get-checkbox-default-small() {
  .get-checkbox-default();
  background: none;
  border-radius: 4px;
  height: 16px;
  width: 16px;
}

.get-checkbox-checked-small() {
  background-color: @color-primary-50;
  border-color: @color-primary-50;

  &:after {
    content: " ";
    border: 2px solid #FFFFFF;
    border-left: 0;
    border-top: 0;
    display: table;
    opacity: 1;
    position: absolute;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    width: 4px;
    height: 10px;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -4px;
  }
}

.get-checkbox-default-middle() {
  .get-checkbox-default();
  background: 50% no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCIgZmlsbD0ibm9uZSI+CjxwYXRoIGQ9Ik0zLjg5ODM4IDYuNjE0MDVMMS4xODI3MiAzLjg5ODM4QzEuMDUyNzcgMy43Njg0OCAwLjg3NjU0NiAzLjY5NTUgMC42OTI4MDEgMy42OTU1QzAuNTA5MDU2IDMuNjk1NSAwLjMzMjgzMyAzLjc2ODQ4IDAuMjAyODg2IDMuODk4MzhDMC4wNzI5NzgxIDQuMDI4MzMgMCA0LjIwNDU1IDAgNC4zODgzQzAgNC41NzIwNCAwLjA3Mjk3ODEgNC43NDgyNyAwLjIwMjg4NiA0Ljg3ODIxTDIuOTE4NTUgNy41OTM4OEMzLjA0NzI1IDcuNzIyNjMgMy4yMDAwNiA3LjgyNDc3IDMuMzY4MjQgNy44OTQ0NUMzLjUzNjQyIDcuOTY0MTMgMy43MTY2OCA4IDMuODk4NzMgOEM0LjA4MDc4IDggNC4yNjEwNCA3Ljk2NDEzIDQuNDI5MjIgNy44OTQ0NUM0LjU5NzQgNy44MjQ3NyA0Ljc1MDIxIDcuNzIyNjMgNC44Nzg5MSA3LjU5Mzg4TDExLjI5MDEgMS4xODI3MkMxMS40MiAxLjA1Mjc3IDExLjQ5MyAwLjg3NjU0NiAxMS40OTMgMC42OTI4MDFDMTEuNDkzIDAuNTA5MDU2IDExLjQyIDAuMzMyODMzIDExLjI5MDEgMC4yMDI4ODZDMTEuMTYwMSAwLjA3Mjk3OCAxMC45ODM5IDAgMTAuODAwMiAwQzEwLjYxNjQgMCAxMC40NDAyIDAuMDcyOTc4IDEwLjMxMDIgMC4yMDI4ODZMMy44OTgzOCA2LjYxNDA1WiIgZmlsbD0iI0ZFRkRGQyIvPgo8L3N2Zz4=");
  border-radius: 4px;
  height: 18px;
  width: 18px;
}

.get-checkbox-checked-middle() {
  background-color: @color-primary-50;
  border-color: @color-primary-50;
}

.get-checkbox-default-large() {
  .get-checkbox-default();
  background-image: none;
  
  background-color: @color-background-50;
  border-radius: 2px;
  height: 24px;
  width: 24px;
}

.get-checkbox-checked-large() {

  &:after {
    content: " ";
    background-color: @color-primary-30;
    border: none;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    top: 50%;
    margin-top: -12px;
    left: 50%;
    margin-left: -12px;
    transform: scale(.5);
  }
}

.@{base-cls}-cnt {
  .@{base-cls}-box-description {
    .mixin-text(small);
    color: @color-text-20;
  }
}


.@{base-cls}-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .@{base-cls} {
      .@{base-cls}-inner {
        .get-checkbox-hover() !important;
      }
    }
  }

  &.@{base-cls}-wrapper-disabled {
    .@{base-cls}-box-label {
      color: @color-text-20 !important;
    }
  }

  .@{base-cls} {
    padding-right: 8px;

    .@{base-cls}-input {
      display: none;
    }

    &.@{base-cls}-disabled {
      .@{base-cls}-inner {
        .get-checkbox-disabled();
      }

      &.@{base-cls}-checked {
        .@{base-cls}-inner {
          .get-checkbox-checked-disabled();
        }
      }
    }
  }

  .@{base-cls}-box-label {
    .mixin-text(medium);
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.@{base-cls}-wrapper-disabled {
    .@{base-cls}-box-label {
      color: @color-text-20;
    }
  }
}

.@{base-cls}-small {
  .@{base-cls} {

    .@{base-cls}-box-label {
      .mixin-text(small);
    }

    .@{base-cls}-inner {
      .get-checkbox-default-small() !important;
    }

    &.@{base-cls}-checked {
      .@{base-cls}-inner {
        .get-checkbox-checked-small() !important;
      }
    }
  }
}

.@{base-cls}-large {
  .@{base-cls} {
    
    .@{base-cls}-box-label {
      .mixin-text(medium);
    }
    
    .@{base-cls}-inner {
      .get-checkbox-default-large() !important;
    }

    &.@{base-cls}-checked {
      .@{base-cls}-inner {
        .get-checkbox-checked-large() !important;
      }
    }
  }
}

.@{base-cls}-middle {
  .@{base-cls} {

    .@{base-cls}-box-label {
      .mixin-text(small);
    }

    .@{base-cls}-inner {
      .get-checkbox-default-middle() !important;
    }

    &.@{base-cls}-checked {
      .@{base-cls}-inner {
        .get-checkbox-checked-middle() !important;
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;