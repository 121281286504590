@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-input;

.@{base-cls}-small {
  border: 0 solid @color-secondary-80;
  border-bottom-width: 1px;
  padding: 4px;
  height: 30px;

  .@{base-cls},
  &.@{base-cls} {
    .mixin-text(small) !important;
  }

  .@{base-cls} {
    &-suffix {
      .@{base-cls}-clear-icon {
        display: flex;
        font-size: 12px;
        color: @color-text-20;
        cursor: pointer;
        &.@{base-cls}-clear-icon-hidden {
          opacity: 0;
        }
      }
    }
  }
  
  .icon {
    font-size: 18px;
  }


}

.@{base-cls}-middle {
  border: 1px solid @color-secondary-80;
  padding: 12px 16px;
  height: 50px;

  .@{base-cls},
  &.@{base-cls} {
    .mixin-text(large);
    font-weight: 400 !important;
  }

  .icon {
    font-size: 24px;
  }

}

.@{base-cls}-large {
  border: 1px solid @color-secondary-80;
  box-shadow: @box-shadow-50;
  border-radius: 4px;
  padding: 12px 16px;
  height: 50px;

  .@{base-cls},
  &.@{base-cls} {
    .mixin-text(large);
    font-weight: 400 !important;
  }

  .icon {
    font-size: 24px;
  }
}

.@{base-cls} {
  width: 100%;
  outline: none;
  margin: 0;

  &.@{base-cls}-disabled {
    background-color: @color-secondary-50;
  }

  &:focus {
    border-color: @color-primary-50;
  }

  &::placeholder {
    color: @color-secondary-100;
  }

  &.@{base-cls}-status-error {
    border-color: @color-error-100 !important;
  }
}

.@{base-cls}-affix-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;

  &.@{base-cls}-affix-wrapper-status-error {
    border-color: @color-error-100 !important;
  }

  &.@{base-cls}-affix-wrapper-focused {
    border-color: @color-primary-50;
  }

  &.@{base-cls}-affix-wrapper-disabled {
    background-color: @color-secondary-50;
  }

  .@{base-cls}-prefix,
  .@{base-cls}-suffix {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .@{base-cls} {
    padding: 0;
    border: none;
  }

  .icon {
    color: @color-text-20;
  }

  &.@{base-cls}-middle,
  &.@{base-cls}-large {
    gap: 8px;

    .@{base-cls}-prefix,
    .@{base-cls}-suffix {
      gap: 8px;
    }
  }
}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;