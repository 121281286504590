@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

.ant-anchor-wrapper {
  background-color: @color-background-50 !important;
  padding: 12px;

  .ant-anchor-link-title {
    white-space: pre-wrap;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;