@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-breadcrumb;

.@{base-cls} {

  &.@{base-cls}-large {
    .@{base-cls}-item-separator {
      margin: 0 12px;
    }
    .@{base-cls}-item-link {
      .mixin-text(large) !important;
      font-weight: 400 !important;

      .icon {
        font-size: 24px;
      }
    }
  }

  &.@{base-cls}-middle {
    .@{base-cls}-item-separator {
      margin: 0 8px;
    }
    .@{base-cls}-item-link {
      .mixin-text(medium) !important;
      font-weight: 400 !important;

      .icon {
        font-size: 24px;
      }
    }
  }

  &.@{base-cls}-small {
    .@{base-cls}-item-separator {
      margin: 0 4px;
    }
    .@{base-cls}-item-link {
      .mixin-text(small) !important;
      font-weight: 400 !important;

      .icon {
        font-size: 20px;
      }
    }
  }


  & > * {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;

      &:last-child {
        .@{base-cls}-item-separator {
          display: none;
        }
      }

      .icon {
        color: @color-text-100;
      }
    }

    .@{base-cls}-item-link {
      color: @color-text-100;
      white-space: normal;

      &:not([href]) {
        color: @color-text-20;
        cursor: default;
      }
    }

    .@{base-cls}-item-separator {
      color: @color-text-20;
      margin: 0 8px;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;