@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-files-modal;

.@{base-cls} {
  .@{base-cls}-select-file{
    width: fit-content;
  }
  .@{base-cls}-form {
    .cmp-space-horizontal{
      align-items: flex-start;
    }

    & > * > .cmp-space-item:last-child {
      flex: 1;
    }

    .@{base-cls}-file-params {
      display: none;

      &.@{base-cls}-select-file {
        display: flex;
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;