@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-flag;

.@{base-cls} {
  position: relative;
  z-index: @layer-100;
  cursor: pointer;


  &.@{base-cls}-active {
    .@{base-cls}-button {
      opacity: 1;
      border-color: @color-primary-30;
    }

    .@{base-cls}-check {
      visibility: visible;
    }
  }

  .@{base-cls}-check {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: -2px;
    background-color: @color-primary-30;
    padding: 4px;
    border-radius: 50%;
    font-size: 8px;
    color: @color-background-50;
    z-index: 2;
  }

  .cmp-popover {
    width: 320px !important;
  }

  .@{base-cls}-unlink {
    visibility: hidden;
    position: absolute;
    padding: 2px !important;
    top: -6px;
    right: -6px;
    z-index: 2;

    .icon {
      font-size: 12px !important;
    }
  }

  &:hover {
    .@{base-cls}-unlink {
      visibility: visible !important;
    }
  }

  .@{base-cls}-button {
    opacity: 0.6;
    border: 2px solid transparent;
    border-radius: 50%;

    &-small {
      .icon {
        font-size: 24px;
      }
    }

    &-default {
      .icon {
        font-size: 32px !important;
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;