@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-popconfirm;

.@{base-cls} {

  &.cmp-popover-cnt {
    width: auto;
    display: block;
    line-height: 0;
  }


  &.cmp-popover-inner {
    .mixin-text(medium);
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 380px;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;