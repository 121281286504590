@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-compare-version-item;

.@{base-cls} {

  .@{base-cls}-content-body {
    word-break: break-word;
    margin: 16px 0;
    .mixin-text(medium);
    font-weight: 400 !important;

    p {
      margin: 0;
    }

    figure{
      max-width: 100%;
      width: fit-content !important;
    }

    img {
      max-width: 100%;
    }
  }

  .@{base-cls}-description {
    .mixin-text(small);
    p {
      margin: 0;
    }

    img {
      max-width: 100%;
    }
  }
  .@{base-cls}-files{
    display: flex;
    & > * {
      flex: 50%;
    }
  }

  .css-1n5o7vh-diff-container {
    border: 1px solid @color-secondary-70;
    margin-right: 16px;
  }

  .css-1rwygp7-title-block {
    background-color: @color-secondary-50 !important;
    border-bottom: 1px solid @color-secondary-80;

    .css-pa3tg-content-text {
      .mixin-text(medium);
    }

    &:last-child {
      border-left: 1px solid @color-secondary-80;
    }
  }

  .css-orf3ir-gutter {
    background-color: @color-secondary-70 !important;
    cursor: auto !important;

    &:hover {
      background-color: @color-secondary-70 !important;
    }

    &.css-rq9a2a-diff-removed {
      background-color: @color-error-100 !important;

      &:hover {
        background-color: @color-error-100 !important;
      }
    }

    &.css-cnnxkz-diff-added {
      background-color: @color-success-100 !important;

      &:hover {
        background-color: @color-success-100 !important;
      }
    }

    &.css-h84nzi-line-number {
      .mixin-text(medium);
      font-weight: 400;
    }

    pre {
      color: @color-text-100 !important;
    }
  }

  .css-17vezug-marker {
    background-color: @color-background-50;

    &.css-rq9a2a-diff-removed {
      background-color: @color-error-50;
    }

    &.css-cnnxkz-diff-added {
      background-color: @color-success-50;
    }

    pre {
      .mixin-text(medium);
      color: @color-text-100 !important;
      font-weight: 400;
    }
  }

  .css-vl0irh-content {

    background-color: @color-background-50;

    &.css-rq9a2a-diff-removed {
      background-color: @color-error-50;
    }

    &.css-cnnxkz-diff-added {
      background-color: @color-success-50;
    }

    .css-pa3tg-content-text {
      .mixin-text(medium);
      color: @color-text-100;
      font-weight: 400;
      line-height: 25px !important;
    }
  }

  .css-cncyp1-word-diff {
    padding: 0 !important;

    &.css-hf3w1f-word-removed {
      background-color: @color-error-100;
      text-decoration: line-through;
    }

    &.css-1u4zuq6-word-added {
      background-color: @color-success-100;
    }
  }
}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;