@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-card;

.@{base-cls} {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid @color-secondary-70;
  box-shadow: @box-shadow-50;
  border-radius: 4px;
  position: relative;
  background-color: @color-secondary-50;

  .@{base-cls}-indicator {
    width: 16px;
    margin-left: -1px;
    background-color: #E7EDF8;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-top: -1px;
    margin-bottom: -1px;

    &-none {
      background-color: @color-secondary-70;
    }

    &-default {
      background-color: @color-primary-30;
    }

    &-warning {
      background-color: @color-warning-50;
    }

    &-success {
      background-color: @color-success-50;
    }

    &-error {
      background-color: @color-error-50;
    }
  }

  &.cmp-card-small {
    & .@{base-cls}-cnt {
      padding: 8px 12px;
    }

    .@{base-cls}-header {
      .@{base-cls}-header-title {
        .mixin-text(small) !important;
      }
    }

  }

  & .@{base-cls}-cnt {
    padding: 14px 16px;
    flex: 1;
    width: 100%;

    .@{base-cls}-header {
      display: flex;
      align-items: center;
      gap: 4px;

      .@{base-cls}-header-title {
        flex: 1;
        .mixin-text(large)
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;