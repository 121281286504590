@base-cls: knowledge-item-form;

.@{base-cls} {

    .@{base-cls}-row {
        &>*:first-child {
            min-width: 320px;
            max-width: 320px;
        }

        & > *:last-child:not(:first-child) {
            flex: 1;
        }

        &.@{base-cls}-row-tablet {
            &>*:first-child {
                min-width: 100%;
                max-width: 100%;
            }
        }

        .cmp-text-editor:not(.cmp-text-editor-full-screen) {
            width: 100%;
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;