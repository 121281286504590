@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-modal;

.@{base-cls}-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: @layer-210;
  background: rgba(50, 50, 50, 0.5);
  overflow: auto;

  .@{base-cls}-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .@{base-cls}-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;

    &.@{base-cls}-wrap-mobile{
      margin-top: 4px;
    }

    .@{base-cls} {
      background: @color-background-50;
      border-radius: 4px;
      margin: 16px;
      padding: 35px 58px 18px;

      .@{base-cls}-content {
        position: relative;

        .@{base-cls}-close {
          position: absolute;
          right: 0;
          top: 0;

          font-size: 24px;
          line-height: 0;
          color: @color-primary-50;
          display: inline-block;
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 0;
        }

        .@{base-cls}-header {
          padding-bottom: 24px;

          .@{base-cls}-title {
            .mixin-typography-text(title);
          }
        }

        .@{base-cls}-footer {
          padding-top: 24px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 16px;
        }
      }


      &.@{base-cls}-mobile {
        padding: 16px 12px;;
        margin: 4px;
        width: 98vw !important;

        .@{base-cls}-footer {
          flex-wrap: wrap;
        }
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;