@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-init-page;

.@{base-cls} {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.@{base-cls}-mobile {
    .@{base-cls}-header {
      padding-left: 0;
      justify-content: center;
    }

    .@{base-cls}-img {
      display: none !important;
    }

    .@{base-cls}-form {
      & > * {
        margin: 0 16px !important;
      }
    }
  }

  .@{base-cls}-header {
    min-height: 58px;
    border-bottom: 2px solid @color-secondary-80;
    display: flex;
    align-items: center;
    padding-left: 22px;
  }

  .@{base-cls}-body {
    display: flex;
    align-items: stretch;
    flex: 1 1;

    .@{base-cls}-img {
      max-width: 50%;
    }

    .@{base-cls}-form {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 82px;

      & > * {
        max-width: 540px;
        margin: 0 32px;
        width: 100%;
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;