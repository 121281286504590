@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-knowledge-list;

.@{base-cls} {

  &.@{base-cls}-small {
    .@{base-cls}-item {
      padding: 8px 16px;
      gap: 8px !important;
    }
  }

  &.@{base-cls}-middle {
    .@{base-cls}-item {
      padding: 16px 24px;
      gap: 8px !important;
    }
  }

  &.@{base-cls}-large {
    .@{base-cls}-item {
      padding: 16px 24px;
      gap: 12px !important;
    }
  }

  .@{base-cls}-item {

    &:nth-child(2n - 1) {
      background-color: fade(@color-primary-10, 40%);
    }

    .@{base-cls}-content {
      .mixin-text(small);

      img {
        max-width: 100%;
      }
    }

    .@{base-cls}-row-version {
      width: 100%;

      .@{base-cls}-content-version {
        width: 100%;
        .mixin-text(small);

        img {
          max-width: 100%;
        }
      }
    }



    .@{base-cls}-link {
      cursor: pointer;
      color: @color-primary-50;
    }
  }

}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;