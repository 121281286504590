@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-denied;

.@{base-cls} {

    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .@{base-cls}-warning-icon {
        font-size: 140px;
        color: @color-primary-10;
        display: inline-block;
        line-height: 100%;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;