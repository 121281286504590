@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-page-list-table;

.@{base-cls}-avatar {
  max-width: fit-content;

  .cmp-upload-item {
    border: 0;
    box-shadow: none;
  }
}

.@{base-cls}-title {
  .cmp-page-list-title-el {
    .mixin-text(medium);
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;