@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-table;

.@{base-cls}-card{
  .cmp-card-cnt {
    padding: 0 !important;
  }
}

.@{base-cls}-wrapper {
  .@{base-cls} {

    table {
      border-collapse: separate;
      border-spacing: 0;
      min-width: 100%;
    }

    .@{base-cls}-column-sorters {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .icon {
        svg {
          font-size: 24px;
        }
      }

      .@{base-cls}-column-sorter-inner {
        display: none;
      }
    }

    .@{base-cls}-filter-column {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      
      .@{base-cls}-filter-trigger {
        margin-left: 8px;
        color: @color-secondary-70;

        &> *{
          cursor: pointer;
        }

        &:not(.active){
          color: @color-secondary-80;
        }

        &.active {
          color: @color-secondary-100;
        }
      }
    }
  }

  .@{base-cls}-sticky-holder {
    position: sticky;
    z-index: @layer-100;
  }
  .@{base-cls}-summary {
    &>tr {
      &>td.@{base-cls}-cell {
        border-bottom: 1px solid @color-secondary-80;

        &:not(:last-child) {
          border-right: 1px solid @color-secondary-80
        }
      }
    }
    .@{base-cls}-cell {
      &:active,
      &:focus {
        outline: none;
      }

      color: @color-text-100;
      background-color: @color-secondary-60;
      .mixin-text(medium);
      padding: 10px 8px 9px;
      word-break: break-all;

      .cmp-table-column-title {
        word-wrap: initial;
      }

      &.@{base-cls}-column-has-sorters {
        cursor: pointer;
      }
    }
  }

  .@{base-cls}-thead {
    &>tr {
      &>th.@{base-cls}-cell {

        border-bottom: 1px solid @color-secondary-80;

        &:last-child {
          border-radius: 0 4px 0 0;
        }

        &:first-child {
          border-radius: 4px 0 0 0;
        }

        &:not(:last-child) {
          border-right: 1px solid @color-secondary-80
        }

        &.@{base-cls}-cell-fix-right {
          text-align: right;
          .@{base-cls}-column-sorters {
            justify-content: flex-end;
          }
        }

        &.@{base-cls}-cell-fix-left {
          text-align: left;
          .@{base-cls}-column-sorters {
            justify-content: flex-start;
          }
        }
      }
    }

    .@{base-cls}-cell {
      &:active,
      &:focus {
        outline: none;
      }

      color: @color-text-100;
      background-color: @color-secondary-60;
      .mixin-text(large);
      padding: 16px 10px;
      word-break: break-all;

      .cmp-table-column-title {
        word-wrap: initial;
      }

      &.@{base-cls}-column-has-sorters {
        cursor: pointer;
      }
    }
  }

  .@{base-cls}-row {
    &:hover {
      .@{base-cls}-cell {
        background-color: @color-secondary-60;
      }
    }

    &:last-child {
      .@{base-cls}-cell {
        border-width: 0 !important;
      }
    }

    .@{base-cls}-cell {
      .mixin-text(medium);
      background-color: @color-background-50;
      border-bottom: 1px solid @color-secondary-70;
      color: @color-text-100;
      padding: 14px 10px 13px;
      min-width: 90px;

      .cmp-popover-cnt {
        padding: 4px;
        display: inline-block;
      }

      &.@{base-cls}-cell-fix-right,
      &.@{base-cls}-cell-fix-left {
        z-index: @layer-100;
      }
    }
  }

  .@{base-cls}-sticky-scroll {
    align-items: center;
    border-top: 1px solid @color-primary-10;
    bottom: 0;
    display: flex;
    opacity: .6;
    background: @color-primary-10;
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;

    .@{base-cls}-sticky-scroll-bar {
      background-color: @color-secondary-80;
      border-radius: 4px;
      height: 8px;

      &:hover {
        background-color: @color-secondary-80;
      }
    }
  }

  .@{base-cls}-middle {
    .@{base-cls}-thead {
      .@{base-cls}-cell {
        padding: 14px 8px;
        .mixin-text(medium);
        line-height: 24px !important;
      }
    }

    .@{base-cls}-row {
      .@{base-cls}-cell {
        padding: 10px 8px 9px;
        .mixin-text(small);
      }
    }
  }

  .@{base-cls}-small {
    .@{base-cls}-thead {
      .@{base-cls}-cell {
        padding: 8px 6px;
        .mixin-text(small);
      }
    }

    .@{base-cls}-body {
      .@{base-cls}-row {
        .@{base-cls}-cell {
          padding: 6px 6px 5px;
          .mixin-text(small);
        }
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;