@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-result;

.@{base-cls} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @layer-300;
    height: 100vh;
    width: 100vw;
    background-color: @color-background-50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 16px 0 16px;
    gap: 16px;
}