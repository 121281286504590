@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-tag;

.@{base-cls} {
  .mixin-tags();
  padding: 4px 6px;
  position: relative;
  width: auto;

  &.@{base-cls}-active {
    cursor: pointer;
  }

  &:not(.@{base-cls}-active) {

    &:after {
      content: " ";
      box-shadow: none !important;
      display: none !important;
    }
  }

  .@{base-cls}-icon {
    .icon {
      font-size: 16px !important;
    }
  }

  .@{base-cls}-close-icon {
    line-height: 0;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;

    .icon {
      font-size: 16px !important;
    }
  }

  &.@{base-cls}-small {
    padding: 0 4px;
    font-size: 10px;

    .@{base-cls}-icon {
      .icon {
        font-size: 12px !important;
      }
    }

    .@{base-cls}-close-icon {
      .icon {
        font-size: 12px !important;
      }
    }
  }

  &.@{base-cls}-large {
    .mixin-text(medium);
    font-weight: 400 !important;

    .@{base-cls}-icon {
      .icon {
        font-size: 16px !important;
      }
    }

    .@{base-cls}-close-icon {
      .icon {
        font-size: 16px !important;
      }
    }
  }

  &.@{base-cls}-warning {
    background: @color-warning-50;
  }

  &.@{base-cls}-success {
    background: @color-success-50;
  }

  &.@{base-cls}-error {
    background: @color-error-50;
  }

}




@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;