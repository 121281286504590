@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-progress-password;


.@{base-cls}-text{
    font-weight: 400 !important;
}

.@{base-cls}-status-text-success {
    color: @color-success-100 !important;
}

.@{base-cls}-status-text-exception {
    color: @color-error-100 !important;
}

.@{base-cls}-status-text-warning {
    color: @color-warning-100 !important;
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;