@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-button;

.@{base-cls}{
  position: relative;

  .icon {
    font-size: 24px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;