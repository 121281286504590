@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-notification;

@keyframes show-notification {
  from {
    transform: translateX(100vh);
  }

  to {
    transform: translateX(0);
  }
}

.@{base-cls} {
  position: fixed;
  z-index: @layer-400;
  margin: 16px;
  max-width: 384px;

  .@{base-cls}-notice {
    display: flex;
    gap: 6px;
    padding: 8px 32px 8px 24px;
    border: 1px solid @color-secondary-70;
    box-shadow: @box-shadow-50;
    border-radius: 4px;
    background-color: @color-background-50;
    position: relative;
    animation-name: show-notification;
    animation-duration: 0.7s;
    min-width: 320px;

    .@{base-cls}-notice-content {
      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
    }

    .@{base-cls}-notice-icon {
      display: none;
    }

    .@{base-cls}-notice-message {
      .mixin-text(large);
      max-width: 320px;
      word-wrap: break-word;
    }

    .@{base-cls}-notice-description {
      .mixin-text(small);
      max-width: 320px;
      word-wrap: break-word;
    }

    .@{base-cls}-notice-close {
      position: absolute;
      top: 4px;
      right: 8px;
      font-size: 18px;
      color: @color-text-100;
    }
  }

  &-fade-leave {
    transition: 1s;
    transform: translateY(-100vh);
  }
}

.@{base-cls}-notice {

  &.@{base-cls}-error {
    background-color: @color-error-10;
  }

  &.@{base-cls}-success {
    background-color: @color-success-10;
  }

  &.@{base-cls}-info {
    background-color: @color-primary-10;
  }

  &.@{base-cls}-warning {
    background-color: @color-warning-10;
  }
}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;