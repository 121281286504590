@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-knowledge-item-versions;

.@{base-cls} {

  &-body {
    display: flex;
    gap: 40px;
    align-items: flex-start;

    .cmp-list {
      min-width: 250px;
    }

    .@{base-cls}-diff {
      width: 100%;
      margin: 0;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;