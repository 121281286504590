  @import "../../helpers/Variables.less";
  @import "../../helpers/Mixins.less";

  @base-cls: cmp-toolbar;

  .@{base-cls} {
      display: flex;
      column-gap: 14px;
      align-items: center;
      align-content: center;
      margin-bottom: 26px;

      &.@{base-cls}-mobile {
          flex-direction: column;
          gap: 12px;
          align-items: start;

          &>.cmp-space {
              width: 100%;

              &>* {
                  width: 100%;
              }

              .@{base-cls}-sort-field {
                  width: 100%;
              }
          }


          .@{base-cls}-input-search {
              width: 100%;
          }
      }

      .@{base-cls}-action {
          flex: 1;

          .@{base-cls}-sort-field {
              float: left;
          }
      }

      .@{base-cls}-icon {
          .icon {
              color: @color-background-50;
              cursor: pointer;

              svg {
                  width: 26px;
                  height: 26px;
              }
          }
      }

      .@{base-cls}-input-search {
          max-width: 482px;
          flex: 1;
      }
  }
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;