@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-slider;

.@{base-cls}-body {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        color: @color-secondary-100;
    }

    .ant-slider {
        flex: 1;
        &:hover .ant-slider-track{
            background-color: @color-primary-30;
        }

        &:hover .ant-slider-handle{
            border-color: @color-primary-30;
        }
    }

    .ant-slider-rail {
        background-color: @color-secondary-80;
    }

    .ant-slider-track {
        background-color: @color-secondary-95;
    }

    .ant-slider-handle {
        background-color: @color-background-50;
        border-color: @color-secondary-100;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;