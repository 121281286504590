@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-radio;

.get-radio-default() {
  border: 1px solid @color-secondary-95;
  border-collapse: separate;
  border-radius: 50%;
  display: block;
  position: relative;
  transition: all .3s;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

.get-radio-disabled() {
  border-color: @color-secondary-95;
  background-color: @color-secondary-95;

  &:after {
    background-color: @color-text-50
  }
}

.get-radio-checked() {
  border-color: @color-secondary-95;

  &:after {
    background-color: @color-primary-30;
    border-radius: 50%;
    content: " ";
    height: 24px;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 24px;
    transform: scale(.6);
    opacity: 1;
  }
}

.@{base-cls}-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 0 6px;

  &.@{base-cls}-hidden {
    .@{base-cls} {
      .@{base-cls}-inner {
        display: none !important;
      }
    }

    &.@{base-cls}-wrapper-checked {
      .@{base-cls}-box-label {
        .mixin-text(medium);
      }
    }

  }

  &.@{base-cls}-wrapper-disabled {
    .@{base-cls}-box-label {
      color: @color-text-50 !important;
    }
  }

  .@{base-cls} {
    line-height: 0;
    padding-right: 8px;

    .@{base-cls}-input {
      display: none;
    }

    .@{base-cls}-inner {
      .get-radio-default();
    }

    &.@{base-cls}-disabled {
      .@{base-cls}-inner {
        .get-radio-disabled() !important;
      }
    }

    &.@{base-cls}-checked {
      .@{base-cls}-inner {
        .get-radio-checked();
      }
    }

  }

  .@{base-cls}-box-label {
    .mixin-text(medium);
    color: @color-text-100;
  }

  &.@{base-cls}-small {
    .@{base-cls} {
      .@{base-cls}-inner {
        width: 12px;
        height: 12px;

        &:after {
          height: 12px;
          margin-left: -6px;
          margin-top: -6px;
          width: 12px;
        }
      }
    }

    .@{base-cls}-box-label {
      .mixin-text(small);
    }
  }

  &.@{base-cls}-large {
    .@{base-cls} {
      .@{base-cls}-inner {
        width: 24px;
        height: 24px;

        &:after {
          height: 24px;
          margin-left: -12px;
          margin-top: -12px;
          width: 24px;
        }
      }
    }
    .@{base-cls}-box-label {
      .mixin-text(large);
    }
  }

}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;