@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-upload-logo;

.@{base-cls} {
    .@{base-cls}-file {
        display: block !important;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    .cmp-avatar-string {
        font-size: 36px;
    }

    .@{base-cls}-file-list-text-container {
        display: none;
    }

    .@{base-cls}-img {
        position: relative;
        width: fit-content;
        height: fit-content;

        img {
            border-radius: 50%;
            max-width: 200px;
            max-height: 200px;
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;