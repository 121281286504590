@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-multi-select;

.@{base-cls}-popover {
  &.cmp-popover {
    width: 100%;
  }

  &.cmp-popover-placement-bottomRight {

    .cmp-popover-content {
      top: 4px;
      left: 28px;

      .cmp-popover-arrow-content {
        right: 36px
      }
    }
  }

  &.cmp-popover-placement-topRight {

    .cmp-popover-content {
      bottom: 4px;
      left: 28px;

      .cmp-popover-arrow-content {
        right: 36px
      }
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;