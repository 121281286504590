@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-favorite-list;

.@{base-cls}-favorite-list {
    overflow: auto;
    display: flex;
    align-items: stretch;
    padding: 0 6px 6px 0;
    gap: 12px;
    flex-wrap: wrap;

    .@{base-cls}-favorite-item {
        min-height: 100px;
        min-width: 220px;
        max-width: 400px;
        flex: 1 1;

        .cmp-card-body {
            height: 100%;

            &>.cmp-space {
                height: 100%;
                justify-content: space-between;
            }

            .@{base-cls}-favorites-title {
                .mixin-text(small) !important;

                .@{base-cls}-favorites-button {
                    color: @color-primary-50;
                    cursor: pointer;
                }
            }

            .@{base-cls}-space-remove {
                justify-content: space-between;
            }

            .@{base-cls}-remove-favorites {
                svg {
                    font-size: 18px !important;
                    color: @color-secondary-100;
                }
            }

            .@{base-cls}-remove-favorites {
                position: relative;
                right: -8px;
            }
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;