@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-blank-page;

.@{base-cls} {
  padding: 29px 58px;

  &.@{base-cls}-mobile {
    padding: 16px 12px;

    .@{base-cls}-title {
      align-items: start;
      flex-direction: column;
      gap: 8px;

      button{
        width: 100%;
      }
    }

    .@{base-cls}-body {
      padding-top: 12px;
    }
  }

  .@{base-cls}-title {
    display: flex;
    align-items: center;
    min-height: 40px;

    .@{base-cls}-breadcrumb {
      flex: 1;
    }
  }

  .@{base-cls}-description {
    color: @color-text-20;
    .mixin-text(small);
    margin-top: 4px;
  }

  .@{base-cls}-body {
    padding-top: 34px;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;