@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-auto-complete;

.@{base-cls} {
  .@{base-cls}-button {
    visibility: hidden;

    &.@{base-cls}-button-show {
      visibility: visible;
    }
  }


  .cmp-combobox-popover {
    max-height: 200px;
    padding-bottom: 0 !important;


    .@{base-cls}-item {
      padding-bottom: 16px;
      cursor: pointer;

      &:hover {
        .@{base-cls}-label {
          color: @color-primary-50;
        }
      }

      .@{base-cls}-item-description {
        margin-top: 8px;
      }

    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;