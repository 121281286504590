@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-button-menu;

.@{base-cls}-popover {
    position: absolute !important;
    z-index: @layer-300;

    &.@{base-cls}-popover-hidden {
        background: @color-background-50;
        display: none !important;
    }

    .@{base-cls}-popover-content {
        position: relative;
        right: 4px;

        .@{base-cls}-popover-arrow {
            display: none;
        }

        .@{base-cls}-popover-inner {
            color: @color-background-50;

            .@{base-cls}-popover-list {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                
                &:not(.@{base-cls}-popover-list-add) {
                    flex-wrap: wrap;
                    width: 60vw;
                }
                .@{base-cls}-popover-item-add {
                    border-left: 1px solid @color-primary-30;
                    border-right: 1px solid @color-primary-30;
                }
                .@{base-cls}-popover-item-add,
                .@{base-cls}-popover-item {
                    white-space: nowrap;
                    max-height: 56px;
                    padding: 18px 24px;
                    cursor: pointer;
                    .mixin-text(medium);
                    text-align: center;
                    background: @color-primary-50;
                    min-width: 160px;

                    &:hover {
                        background-color: @color-primary-30;
                    }
                }
            }
        }
    }
}

.@{base-cls}-popover-body {
    display: flex;
}


.@{base-cls}-item {
    position: relative;
    cursor: pointer;
    color: @color-text-20;
    height: 56px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .@{base-cls}-inner-tooltip {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .@{base-cls}-button {
        .cmp-button-loading-icon-motion,
        .icon {
            color: @color-text-20 !important;
        }

        &.cmp-button-loading {
            .icon {
                display: none;
            }
        }
    }

    .icon {
        font-size: 20px;
    }
}

.@{base-cls}-item-selected,
.@{base-cls}-popover-open {
    background: @color-primary-50;
    color: @color-background-50;

    .@{base-cls}-inner-tooltip { 
        background: @color-primary-50;
            color: @color-background-50;
    }
    .@{base-cls}-button {
        .cmp-button-loading-icon-motion,
        .icon {
            color: @color-background-50 !important;
        }
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;