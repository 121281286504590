@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-upload-item;

.@{base-cls} {
  border: 1px solid @color-secondary-80;
  box-shadow: 4px 4px 8px 0 #00000012;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background-color: @color-secondary-50;

  .@{base-cls}-tag {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px
  }

  &.@{base-cls}-large {
    .@{base-cls}-info {
      width: 152px;
      height: 152px;
      justify-content: center !important;

      .@{base-cls}-icon {
        font-size: 112px;
      }
    }

  }

  &.@{base-cls}-middle {
    .@{base-cls}-info {
      width: 96px;
      min-height: 96px;
      max-height: 96px * 1.5;
      padding: 10px;
      height: 100%;

      .@{base-cls}-icon {
        font-size: 64px;
      }
    }
  }

  &.@{base-cls}-small {
    .@{base-cls}-info {
      width: 42px;
      min-height: 42px;
      max-height: 42px * 1.5;
      padding: 5px;
      height: 100%;

      .@{base-cls}-icon {
        font-size: 24px;
      }
    }
  }

  .@{base-cls}-name {
    color: @color-text-20;
    .mixin-text(small);
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
  }

  .@{base-cls}-name-hide {
    display: none;
  }

  .@{base-cls}-info {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    gap: 8px;

    .@{base-cls}-icon {
      display: flex;

      img {
        border-radius: 4px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &>* {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .@{base-cls}-button-remove {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: @color-secondary-80 !important;
    border-radius: 50%;
    padding: 0 !important;

    .icon {
      color: @color-primary-50 !important;
      font-size: 16px !important;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;