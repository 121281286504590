@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-avatar;

.@{base-cls} {
    font-size: 14px;
    list-style: none;
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: @color-background-50;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: @color-secondary-95;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;

    img {
        width: 100%;
        top: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;