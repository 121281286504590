@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-avatar;

.@{base-cls} {
  background-color: @color-secondary-50 !important;
  border-radius: 40px !important;
  border: 1px solid @color-secondary-100 !important;

  .cmp-button-text {
    padding: 4px 4px 4px 16px;
  }

  .@{base-cls}-label {
    white-space: nowrap;
  }

  &.@{base-cls}-mobile {
    border-radius: none !important;
    border: none !important;

    .cmp-button-text {
      padding: 0px !important;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;