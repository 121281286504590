@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-combobox;

.@{base-cls}-popover {
  &.cmp-popover-cnt {
    flex: 1;

    &.@{base-cls}-popover-hide {
      .cmp-popover-content {
        display: none;
      }
    }

    & > *:not(:first-child):last-child {
      position: relative !important;
      height: 0 !important;

      & > * {
        height: 0 !important;
      }
    }
  }

  &.cmp-popover {
    width: 100%;
  }

  .cmp-popover-inner {
    max-height: 400px;
    overflow: auto;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;