@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-tags-list;


.@{base-cls} {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 500px;
  align-items: center;

  .@{base-cls}-item {

    &:not(.@{base-cls}-active) {
      opacity: 0.5;
    }

    &.@{base-cls}-active {
      opacity: 1;
    }
    
    &.@{base-cls}-default {
      cursor: default;
    
      span {
        cursor: text;
      }
    }
  }
}

.@{base-cls}-form {

  .@{base-cls}-circle {
    padding: 4px 12px;
    .mixin-text(small);
    border-radius: 4px;

    &-default {
      background-color: @color-primary-10;
    }

    &-warning {
      background-color: @color-warning-50;
    }

    &-success {
      background-color: @color-success-50;
    }
  }
}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;