@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: knowledge-version-form;

.@{base-cls}-row {

  & > *:first-child {
    max-width: 320px;
  }

  & > *:last-child {
    flex: 1;
  }

  .cmp-text-editor:not(.cmp-text-editor-full-screen) {
    width: 100%;
  }
}

.@{base-cls}-field-annotation {
  .cmp-text-editor {
    max-height: 180px;
    min-width: 280px;

    .tox-editor-header {
      display: none;
    }
  }
}

.@{base-cls}-name {
  label {
    text-wrap: nowrap;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;