@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-textarea;

.@{base-cls}-textarea-show-count {
  position: relative;

  &:after {
    position: absolute;
    bottom: 8px;
    right: 6px;
    color: @color-secondary-100;
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
    .mixin-text(small);
  }
}

.@{base-cls} {
  width: 100%;
  padding: 16px;
  outline: none;
  border: 1px solid @color-secondary-80;
  margin: 0;
  .mixin-text(large);
  font-weight: 400 !important;



  &:focus {
    border-color: @color-primary-50;
  }

  &.@{base-cls}-disabled {
    background-color: @color-secondary-50;
  }

  &.@{base-cls}-status-error {
    border-color: @color-error-100 !important;
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;