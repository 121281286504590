@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-list;


.@{base-cls} {

  .@{base-cls}-link {
    color: @color-primary-50;
  }

  .@{base-cls}-item {
    cursor: pointer;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid @color-secondary-95;
    display: flex;
    align-items: center;
    margin-right: 44px;
    gap: 20px;

    .@{base-cls}-item-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      gap: 4px;

      .@{base-cls}-label {
        .mixin-text(medium);
        line-height: 24px;
        flex: 1;
      }

      .@{base-cls}-description {
        .mixin-text(small);
        color: @color-text-20;
        flex: 1;
      }
    }

    &.@{base-cls}-item-active {
      margin-right: 0;
      border-bottom-color: @color-primary-50;
      font-weight: 700;

      .@{base-cls}-label {
        color: @color-primary-50;
        font-weight: 700;
      }

      .@{base-cls}-icon {
        color: @color-primary-50;
        display: inline-block;
      }
    }

    .@{base-cls}-icon {
      line-height: 0;
      display: none;
      font-size: 24px;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;