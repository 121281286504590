@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-typography;

.@{base-cls} {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;

  &.@{base-cls} {

    &-default {
      color: @color-text-100;
    }
    &-secondary {
      color: @color-text-50;
    }
    &-success {
      color: @color-success-100;
    }
    &-warning {
      color: @color-warning-100;
    }
    &-danger {
      color: @color-error-100;
    }
    &-disabled {
      color: @color-text-20 !important;
    }
    &-link {
      color: @color-primary-50;
    }

    &-small {
      .mixin-text(small)
    }
    &-middle {
      .mixin-text(medium)
    }
    &-large {
      .mixin-text(large)
    }

    &-title {
      .mixin-typography-text(title);
    }

  }


}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;