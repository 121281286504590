@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-space;

.@{base-cls} {
  display: flex;

  &.@{base-cls}-vertical {
    flex-direction: column;
    align-items: stretch;

    &.@{base-cls}-align {
      &-start {
        align-items: flex-start;
      }
      &-center {
        align-items: center;
      }
      &-end {
        align-items: flex-end;
      }
    }
    
    &.@{base-cls}-space-between{
      justify-content: space-between;
    }
    &.@{base-cls}-space-around {
      justify-content: space-around;
    }
  }

  &.@{base-cls}-horizontal {
    flex-direction: row;
    align-items: center;

    &.@{base-cls}-align {
      &-start {
        align-items: flex-start;
      }
      &-center {
        align-items: center;
      }
      &-end {
        align-items: flex-end;
      }
    }

    &.@{base-cls}-space-between {
      justify-content: space-between;
    }

    &.@{base-cls}-space-around {
      justify-content: space-around;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;