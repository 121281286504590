@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-view-item;

.@{base-cls} {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100%;

  .@{base-cls}-row-projects {
    &:not(:hover) {
      .cmp-button {
        visibility: hidden;
      }
    }
  }
  
  .@{base-cls}-body {
    height: fit-content;
  }
  
  .@{base-cls}-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .@{base-cls}-title-break{
      word-break: break-word;
    }

    .@{base-cls}-actions {
      justify-content: flex-end;
    }
  }

  .@{base-cls}-pencil {
    cursor: pointer;
  }

  .@{base-cls}-keywords {

    .cmp-tags-input {
      width: 100%;

      .cmp-button-type-dropdown {
        padding: 4px !important;

        .icon {
          font-size: 14px !important;
        }
      }
    }
  }



  .@{base-cls}-link {
    cursor: pointer;
    color: @color-primary-50;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &>* {
    max-width: 1400px;
    width: 100%;

    .@{base-cls}-tags {
      margin-bottom: 36px;
      display: flex;

      &>* {
        margin-right: 16px;
      }
    }

    .@{base-cls}-title {
      margin-bottom: 24px;
    }

    .@{base-cls}-content {
      margin-bottom: 36px;
    }
  }


    .@{base-cls}-space-content {
      justify-content: space-between;
      position: relative !important;

      .@{base-cls}-content-body {
        word-break: break-word;
        margin: 16px 0;
        .mixin-text(medium);
        font-weight: 400 !important;

        p {
          margin: 0;
        }
        
        figure{
          max-width: 100%;
          width: fit-content !important;
        }

        img {
          max-width: 100%;
        }
      }

      .@{base-cls}-copy:not(.cmp-copy-button-success) {
        visibility: hidden;
      }

      &:hover {
        .@{base-cls}-copy {
          visibility: visible;
        }
      }
    }




  .@{base-cls}-files {
    margin: 16px 0;
  }
}

.@{base-cls}-active-keywords {
  opacity: 1 !important;
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;