@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-button-news-list;

.@{base-cls}-top {
    display: flex;
    justify-content: space-between;


    &>*:first-child {
        flex: 1;
        max-width: 340px;
    }

    &.@{base-cls}-top-mobile {
        flex-direction: column-reverse !important;
        align-items: baseline !important;

        &>* {
            width: 100%;

            &:first-child {
                width: 100%;
            }

            .cmp-space {
                justify-content: space-between !important;
            }
        }
    }
}

.@{base-cls}-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .@{base-cls}-sort-star {
        font-size: 16px;
        color: @color-warning-100;
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;