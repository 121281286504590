@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-upload;

.@{base-cls}-cnt {

  & > * {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .@{base-cls}-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &.@{base-cls}-uploaded {
    .@{base-cls} {
      display: none;
    }
  }

  .@{base-cls} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    min-width: 150px;
    cursor: pointer;

    &.@{base-cls}-drag {
      border: 1px dashed @color-primary-50 !important;
      position: relative;

      .@{base-cls}-drag-container {
        max-width: 260px;
        text-align: center;
        .icon {
          font-size: 40px;
        }
      }

      &:hover {
        border: 1.5px dashed @box-shadow-50;
      }
    }

  }
}


@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;