@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-flags-list;

.@{base-cls} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    .@{base-cls}-list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }

    .@{base-cls}-create-small {
        transform: scale(0.75);
    }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;