@base-cls: cmp-files-list;

.@{base-cls}-field {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;

  .cmp-popover-cnt {
    display: flex;
    align-items: center;
  }
}

.@{base-cls}-search {
  max-width: 320px;
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;