@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-checkbox;

.@{base-cls}-group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.@{base-cls}-vertical {
    flex-direction: column;
  }

  .@{base-cls}-separator {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid @color-secondary-70;
  }

  &.@{base-cls}-horizontal {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;