@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-copy-button;


.@{base-cls}-copy {
    opacity: 0.7;
    
    &:hover {
        opacity: 1;
    }
    
    &.@{base-cls}-success {
        opacity: 1 !important;
        .icon {
            color: @color-success-100 !important;
        }
    }
}

.@{base-cls}-textarea{
    position: fixed;
    cursor: none;
    pointer-events: none;
    transform: scale(0);
    opacity: 0;
    width: 0;
    height: 0;
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;