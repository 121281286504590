@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-main-search-filter;

.@{base-cls} {
  .@{base-cls}-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .@{base-cls}-sort-item {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .@{base-cls}-sort-icon {
      font-size: 16px;
      color: @color-warning-100;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;