@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-tags-input;

.@{base-cls}-loader {
  width: 20px;
  height: 20px;

  &>div {
    display: flex !important;

    .cmp-loader-spin {
      position: static !important;
      max-width: 20px !important;
      max-height: 20px !important;
      margin: 0 !important;

    }
  }

  .cmp-loader-container {
    min-height: 0 !important;
  }
}

.@{base-cls} {
  display: flex;
  flex-direction: column;
  position: relative;

  &.cmp-popover-cnt {

    .cmp-input-affix-wrapper {
      flex-wrap: wrap !important;
      height: auto !important;
      justify-content: space-between !important;

      .cmp-input-prefix {
        flex-wrap: wrap !important;

      }

      .cmp-input-suffix {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        flex-wrap: wrap;
      }

      input {
        flex: 1 1;
        min-width: 150px;
      }

      .cmp-input-suffix {
        max-width: 24px;
      }

      .@{base-cls}-button-create {
        visibility: hidden;

        &.@{base-cls}-button-create-show {
          visibility: visible;
        }
      }
    }
  }

  .cmp-popover-inner {
    padding: 16px;
    overflow-x: hidden !important;

    .cmp-popover-inner-content {
      display: flex;
      gap: 8px;

      .@{base-cls}-item {
        font-weight: 400;
        cursor: pointer;
        line-height: 1.5715;

        &:hover {
          color: @color-primary-50;
        }
      }
    }
  }
}

.@{base-cls}-form {

  .@{base-cls}-circle {
    padding: 4px 12px;
    .mixin-text(small);
    border-radius: 4px;

    &-default {
      background-color: @color-primary-10;
    }

    &-warning {
      background-color: @color-warning-50;
    }

    &-success {
      background-color: @color-success-50;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;